import { useRef, useEffect, useMemo } from 'react'
import Cleave from 'cleave.js/react';
import 'cleave.js/dist/addons/cleave-phone.tr.js'

import Icon from '@wearetla/tla-essentials-tools/partials/icon';

const TextInput = ({
	// eslint-disable-next-line no-unused-vars
	inputType: inputTypeProp, onChangePlainValue, onChange, initialized,
	className, label: labelProp, validationError,
	autoComplete, value, mask, maskOptions, innerContent, placeholder, icon,
	hideError, regexFilter: rawRegexFilter,
	...props
}) => {
	const inputRef = useRef();

	// Set initial plain value.
	const plainValue = useMemo(() => {
		if(inputTypeProp === 'phone') {
			let plainValue = value.replace(/\s/g,'');
			if(!plainValue.startsWith('+')){
				if(plainValue.startsWith('00')){
					plainValue = plainValue.replace('00', '+');
				}
				else if(plainValue.startsWith('0')) {
					plainValue = '+9' + plainValue;
				}
				else {
					plainValue = '+90' + plainValue;
				}
			}
			return plainValue;
		}
		return value;
	}, [value, inputTypeProp])

	const isFull = useMemo(() => !!value?.length, [value])

	const label = useMemo(() => {
		return labelProp ?? placeholder;
	}, [placeholder, labelProp])

	const regexFilter = useMemo(() => rawRegexFilter?.length ? new RegExp(rawRegexFilter, 'ig') : undefined, [rawRegexFilter])

	useEffect(() => {
		onChangePlainValue(plainValue);
	}, [plainValue])

	const { component: Component, ...computedProps } = useMemo(() => {
		let props = {
			type: 'text',
			component: 'input'
		}
		if(inputTypeProp === 'phone') {
			props.component = Cleave;
			props.options = {
				phone: true,
				phoneRegionCode: 'TR',
				delimiter: ' ',
				...(maskOptions ?? {}),
			}
			props.inputMode = 'tel';
			props.pattern = '[0-9.]+';
		}
		else if(inputTypeProp === 'number') {
			props.component = Cleave;
			props.options = {
				numeral: true,
				numeralThousandsGroupStyle: 'none',
				numeralDecimalMark: ',',
				stripLeadingZeroes: false,
				...(maskOptions ?? {}),
			}
			props.inputMode = 'numeric';
			props.pattern = '[0-9.]';
		}
		else if(mask) {
			props.component = Cleave;

			if(mask === 'creditCard') {
				props.pattern = '[0-9]*'
				props.inputMode = 'numeric';

				props.options = {
					creditCard: true,
					...(maskOptions ?? {}),
				}
			}
			else if (mask === 'expiry') {
				props.pattern = '[0-9]*';
				props.inputMode = 'numeric';

				props.options = {
					date: true,
					datePattern: ['m', 'y'],
					...(maskOptions ?? {}),
				}
			}
			else {
				props.options = maskOptions ?? {};
			}
		}

		return props;
	}, [inputTypeProp, mask, maskOptions])

	const handleChange = e => {
		if(regexFilter) {
			onChange(e.target.value.replace(regexFilter, ''));
		}
		else {
			onChange(e.target.value);
		}
	}

	useEffect(() => {
		if (inputRef.current && typeof inputRef.current === 'function' && inputRef.current.matches(':-internal-autofill-selected')) {
			inputRef.current.click();
		}
	}, [])

	return (
		<div className={`${className} type-text${isFull ? ' input-full' : ''}`}>
			<div className={'inputwrap-outerwrap' + (label ? '' : ' no-label')}>
				{icon &&
					<Icon className="input-icon" name={icon} />
				}
				{label &&
					<label className="input-label" htmlFor={props.id}>
						<div className="label-text">
							{label}
							{/*((vm.state.validation !== false && vm.state.validation.required) && vm.props.showValidationAsterisk) && <span className="label-asterisk">*</span>*/}
						</div>
						{props.labelNth && <span className="label-nth">{props.labelNth[0] + '/' + props.labelNth[1]}</span>}
					</label>
				}
				<div className="input-innerwrap">
					{innerContent}
					<Component
						ref={inputRef}
						onChange={handleChange}
						className={'inputwrap-input'}
						autoComplete={autoComplete || 'off'}
						value={value}
						placeholder={placeholder ?? label}
						{...computedProps}
						{...props} />
				</div>
				{!!(validationError && !hideError) &&
					<div className="input-error">
						{validationError}
					</div>
				}
			</div>
		</div>
	)
}

export default TextInput;